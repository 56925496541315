import axios from 'axios';
import toast from './Toast';

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 0
});

// apply interceptor on response
AxiosInstance.interceptors.response.use(
    response => response,
    (error) => {
        var ErrorMessage = "";

        //Setup Generic Response Messages
        if (error.response.status === 401) {
            ErrorMessage = 'UnAuthorized'
        } else if (error.response.status === 404) {
            ErrorMessage = 'API Route is Missing or Undefined'
        } else if (error.response.status === 405) {
            ErrorMessage = 'API Route Method Not Allowed'
        } else if (error.response.status === 422) {
            //Validation Message
        } else if (error.response.status >= 500) {
            ErrorMessage = 'Server Error'
        }

        toast.error(ErrorMessage, 'Error Code:' + error.response.status);
    }
);

export default AxiosInstance;
