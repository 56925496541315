import AxiosInstance from '../../common/axios_config/AxiosInstance';

export const business_service = async (CompanyName, LeadFullName, LeadDesignation, LeadWorkingEmail, LeadPhoneNumber, LeadMessage) => {
    var return_data = "";

    await AxiosInstance({
        method: 'post',
        url: 'business_Service/RegisterLead',
        errorHandler: true,
        data: {
            CompanyName: CompanyName,
            LeadFullName: LeadFullName,
            LeadDesignation: LeadDesignation,
            LeadWorkingEmail: LeadWorkingEmail,
            LeadPhoneNumber: LeadPhoneNumber,
            LeadMessage: LeadMessage
        }
    })
        .then(response => {
            if (response != null) {
                return_data = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    return return_data;
};