import 'izitoast/dist/css/iziToast.min.css';
import iZtoast from 'izitoast';

const Toast = {
  error: (message, title) => {
    return iZtoast.error({
      title: title,
      message: message,
      position: 'bottomRight',
      pauseOnHover: true,
      layout: 2,
      displayMode: 2,
      overlay: true,
      titleSize: 15,
      messageSize: 15
    });
  },
  success: (message, title = 'Success') => {
    return iZtoast.success({
      title: title,
      message: message,
      position: 'bottomRight',
      pauseOnHover: true,
      layout: 2,
      displayMode: 2
    });
  }
};

export default Toast;
