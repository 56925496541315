import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";

import State_Merge from '../common/state_merge/state_merge';
import email_format_RegExp from '../common/validation/email/email_format_RegExp';
import toast from '../common/axios_config/Toast';
import { Loading } from '../common/loading/custom_loading/loading';

import { business_service } from '../services/Contact_Us/Contact_Us';

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../assets/styles/components/custom-react-phone-input-2.css";

export default function Contact_Us() {
    const [showLoading, setShowLoading] = useState(false);
    const [disableBtnSave, setDisableBtnSave] = useState(false);

    const [leadDetails, setLeadDetails] = State_Merge({
        txt_lead_company_name: "",
        txt_lead_full_name: "",
        txt_lead_designation: "",
        txt_working_email_address: "",
        txt_phone_number: "",
        txt_lead_message: "",
        errors: {
            txt_lead_company_name: "",
            txt_lead_full_name: "",
            txt_lead_designation: "",
            txt_working_email_address: "",
            txt_phone_number: "",
            txt_lead_message: "",
        }
    });

    const { txt_lead_ID,
        txt_lead_company_name,
        txt_lead_full_name,
        txt_lead_designation,
        txt_working_email_address,
        txt_phone_number,
        txt_lead_message,
        errors } = leadDetails;

    const handleChange = (e) => {
        const { name, value } = e.target;

        setLeadDetails({
            [name]: value,
        });

        let validEmailRegex = email_format_RegExp;

        switch (name) {
            case 'txt_lead_company_name':
                if (value == "") {
                    errors.txt_lead_company_name = "This is a required field"
                }
                else {
                    errors.txt_lead_company_name = "";
                }
                break;
            case 'txt_lead_full_name':
                if (value == "") {
                    errors.txt_lead_full_name = "This is a required field"
                }
                else {
                    errors.txt_lead_full_name = "";
                }
                break;
            case 'txt_lead_designation':
                if (value == "") {
                    errors.txt_lead_designation = "This is a required field"
                }
                else {
                    errors.txt_lead_designation = "";
                }
                break;
            case 'txt_working_email_address':
                if (value == "") {
                    errors.txt_working_email_address = "This is a required field"
                }
                else if (!validEmailRegex.test(value)) {
                    errors.txt_working_email_address = "Invalid Email";
                }
                else {
                    errors.txt_working_email_address = "";
                }
                break;
            case 'txt_phone_number':
                if (value == "") {
                    errors.txt_phone_number = "This is a required field"
                }
                else {
                    errors.txt_phone_number = "";
                }
                break;
            case 'txt_lead_message':
                if (value == "") {
                    errors.txt_lead_message = "This is a required field"
                }
                else {
                    errors.txt_lead_message = "";
                }
                break;
            default:
                break;
        }

        setLeadDetails({
            errors: errors,
        });
    }

    const handlePhoneNumberChange = (value) => {
        setLeadDetails({
            txt_phone_number: value,
        });

        if (value == "") {
            errors.txt_phone_number = "This is a required field"
        }
        else {
            errors.txt_phone_number = "";
        }

        setLeadDetails({
            errors: errors,
        });
    }

    const validateForm = () => {
        let valid = true;
        let validEmailRegex = email_format_RegExp;

        if (txt_lead_company_name == "") {
            errors.txt_lead_company_name = "This is a required field";
            valid = false;
        }
        else {
            errors.txt_lead_company_name = "";
        }


        if (txt_lead_full_name == "") {
            errors.txt_lead_full_name = "This is a required field";
            valid = false;
        }
        else {
            errors.txt_lead_full_name = "";
        }


        if (txt_lead_designation == "") {
            errors.txt_lead_designation = "This is a required field";
            valid = false;
        }
        else {
            errors.txt_lead_designation = "";
        }


        if (txt_working_email_address == "") {
            errors.txt_working_email_address = "This is a required field";
            valid = false;
        }
        else if (!validEmailRegex.test(txt_working_email_address)) {
            errors.txt_working_email_address = "Invalid Email";
            valid = false;
        }
        else {
            errors.txt_working_email_address = "";
        }


        if (txt_phone_number == "") {
            errors.txt_phone_number = "This is a required field";
            valid = false;
        }
        else {
            errors.txt_phone_number = "";
        }


        if (txt_lead_message == "") {
            errors.txt_lead_message = "This is a required field";
            valid = false;
        }
        else {
            errors.txt_lead_message = "";
        }


        setLeadDetails({
            errors: errors,
        });

        return valid;
    }

    const handleInsert = async () => {
        if (validateForm()) {
            setShowLoading(true);
            setDisableBtnSave(true);

            var title = "Register Interest";
            var message = "";

            var return_data = await business_service(txt_lead_company_name, txt_lead_full_name, txt_lead_designation, txt_working_email_address, txt_phone_number, txt_lead_message);

            if (return_data == "Success") {
                message = "Success";
                toast.success(title, message);

                setLeadDetails({
                    txt_lead_company_name: "",
                    txt_lead_full_name: "",
                    txt_lead_designation: "",
                    txt_working_email_address: "",
                    txt_phone_number: "",
                    txt_lead_message: ""
                });
            }
            else {
                message = "Error";
                toast.error(title, message);
            }

            setShowLoading(false);
            setDisableBtnSave(false);
            setLeadDetails({
                insertedRecord: false
            });
        }
    }

    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-20 flex content-center items-center justify-center">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: `url(${require("assets/img/component-img.png").default})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
                                <div className="pr-12">
                                    <h1 className="text-white font-semibold text-5xl">
                                        Connect with Us
                                    </h1>
                                    <p className="mt-4 text-lg text-blueGray-200">

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-risiko-guardian-bg fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </div>

                {/*<section className="relative py-20">*/}
                {/*    <div*/}
                {/*        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"*/}
                {/*        style={{ transform: "translateZ(0)" }}*/}
                {/*    >*/}
                {/*        <svg*/}
                {/*            className="absolute bottom-0 overflow-hidden"*/}
                {/*            xmlns="http://www.w3.org/2000/svg"*/}
                {/*            preserveAspectRatio="none"*/}
                {/*            version="1.1"*/}
                {/*            viewBox="0 0 2560 100"*/}
                {/*            x="0"*/}
                {/*            y="0"*/}
                {/*        >*/}
                {/*            <polygon*/}
                {/*                className="text-white fill-current"*/}
                {/*                points="2560 0 2560 100 0 100"*/}
                {/*            ></polygon>*/}
                {/*        </svg>*/}
                {/*    </div>*/}

                {/*    <div className="container mx-auto px-4">*/}
                {/*        <div className="items-center flex flex-wrap">*/}
                {/*            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">*/}
                {/*                <img*/}
                {/*                    alt="..."*/}
                {/*                    className="max-w-full rounded-lg shadow-lg"*/}
                {/*                    src={require("assets/img/component-img.png").default}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">*/}
                {/*                <div className="md:pr-12">*/}
                {/*                    <h3 className="text-3xl font-semibold">Contact Us</h3>*/}
                {/*                    <ul className="list-none mt-6">*/}
                {/*                        <li className="py-2">*/}
                {/*                            <div className="flex items-center">*/}
                {/*                                <div>*/}
                {/*                                    <h4 className="text-blueGray-500">*/}
                {/*                                        <b>Address</b><br />*/}
                {/*                                        B905, Block B, Sterling PJ Condominium,<br />*/}
                {/*                                        47301 Petaling Jaya,<br />*/}
                {/*                                        Selangor.*/}
                {/*                                    </h4>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </li>*/}
                {/*                        <li className="py-2">*/}
                {/*                            <div className="flex items-center">*/}
                {/*                                <div>*/}
                {/*                                    <h4 className="text-blueGray-500">*/}
                {/*                                        <b>Contact Details</b><br />*/}
                {/*                                        Email: <br />*/}
                {/*                                        Phone: <br />*/}
                {/*                                        Fax: <br />*/}
                {/*                                    </h4>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}

                <section className="pb-4 relative block risiko-guardian-bg">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-risiko-guardian-bg fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4 pb-64">
                    </div>
                </section>

                <section className="relative block py-10 pt-0 risiko-guardian-bg">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                                    <div className="flex-auto p-5 lg:p-10">
                                        <h4 className="text-2xl font-semibold">
                                            Connect with Us
                                        </h4>
                                        <div className="relative w-full mb-3 mt-8">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="company-name"
                                            >
                                                Company Name
                                            </label>
                                            <input
                                                id="txt_lead_company_name"
                                                name="txt_lead_company_name"
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Company Name"
                                                value={txt_lead_company_name}
                                                onChange={handleChange}
                                            />

                                            {errors.txt_lead_company_name.length > 0 &&
                                                <>
                                                    <br />
                                                    <span className='text-danger'>{errors.txt_lead_company_name}</span>
                                                </>
                                            }
                                        </div>

                                        <div className="relative w-full mb-3 mt-8">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="full-name"
                                            >
                                                Full Name
                                            </label>
                                            <input
                                                id="txt_lead_full_name"
                                                name="txt_lead_full_name"
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Full Name"
                                                value={txt_lead_full_name}
                                                onChange={handleChange}
                                            />

                                            {errors.txt_lead_full_name.length > 0 &&
                                                <>
                                                    <br />
                                                    <span className='text-danger'>{errors.txt_lead_full_name}</span>
                                                </>
                                            }
                                        </div>

                                        <div className="relative w-full mb-3 mt-8">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="designation"
                                            >
                                                Designation
                                            </label>
                                            <input
                                                id="txt_lead_designation"
                                                name="txt_lead_designation"
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Designation"
                                                value={txt_lead_designation}
                                                onChange={handleChange}
                                            />

                                            {errors.txt_lead_designation.length > 0 &&
                                                <>
                                                    <br />
                                                    <span className='text-danger'>{errors.txt_lead_designation}</span>
                                                </>
                                            }
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="work-email"
                                            >
                                                Work Email
                                            </label>
                                            <input
                                                id="txt_working_email_address"
                                                name="txt_working_email_address"
                                                type="email"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Work Email"
                                                value={txt_working_email_address}
                                                onChange={handleChange}
                                            />

                                            {errors.txt_working_email_address.length > 0 &&
                                                <>
                                                    <br />
                                                    <span className='text-danger'>{errors.txt_working_email_address}</span>
                                                </>
                                            }
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="phone-number"
                                            >
                                                Phone Number
                                            </label>
                                            <PhoneInput
                                                id="txt_phone_number"
                                                name="txt_phone_number"
                                                country={"my"}
                                                preferredCountries={["my", "sg"]}
                                                value={txt_phone_number}
                                                onChange={handlePhoneNumberChange}
                                                enableSearch={true}
                                                containerClass="relative w-full mb-3"
                                                inputClass="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            />

                                            {errors.txt_phone_number.length > 0 &&
                                                <>
                                                    <br />
                                                    <span className='text-danger'>{errors.txt_phone_number}</span>
                                                </>
                                            }
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="message"
                                            >
                                                Message
                                            </label>
                                            <textarea
                                                id="txt_lead_message"
                                                name="txt_lead_message"
                                                rows="4"
                                                cols="80"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                placeholder="Type a message..."
                                                value={txt_lead_message}
                                                onChange={handleChange}
                                            />

                                            {errors.txt_lead_message.length > 0 &&
                                                <>
                                                    <br />
                                                    <span className='text-danger'>{errors.txt_lead_message}</span>
                                                </>
                                            }
                                        </div>
                                        <div className="text-center mt-6">
                                            <button
                                                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                disabled={disableBtnSave}
                                                onClick={() => handleInsert()}
                                            >
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {showLoading && <Loading />}
            </main>
            <Footer />
        </>
    );
}
