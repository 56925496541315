import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/custom.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import About_Us from "views/About_Us.js";
import News_And_Blog from "views/News_And_Blog.js";
import Contact_Us from "views/Contact_Us.js";
import Index from "views/Index.js";

const subfolder = process.env.REACT_APP_SUBDOMAIN; // Update this with your actual subfolder name

ReactDOM.render(
    <BrowserRouter basename={subfolder}>
        <Switch>
            {/* add routes with layouts */}
            {/* add routes without layouts */}
            <Route path="/about_us" exact component={About_Us} />
            <Route path="/news_and_blog" exact component={News_And_Blog} />
            <Route path="/contact_us" exact component={Contact_Us} />
            <Route path="/" exact component={Index} />
            {/* add redirect for first page */}
            <Redirect from="*" to="/" />
        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
