/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-20 flex content-center items-center justify-center">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: `url(${require("assets/img/component-img.png").default})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
                                <div className="pr-12">
                                    <h1 className="text-white font-semibold text-5xl">
                                        Simplicity yet Robust
                                    </h1>
                                    <p className="mt-4 text-lg text-blueGray-200">
                                        Risiko Guardian is an integrated solution digitalise your GRC practices, enables a holistic approach to enterprise-wide Governance, Risk & Compliance (GRC) activities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-risiko-guardian-bg fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </div>

                <section className="relative py-10">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-white fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>

                    <div className="items-center flex flex-wrap">
                        <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
                            <p className="text-lg leading-relaxed mt-4 mb-4">
                                <b>Risiko Guardian (RG)</b> Solution streamlines Governance, Risk & Compliance (GRC) processes by automating reporting. This integrated solution eliminates manual tasks, ensuring timely and accurate reporting.
                            </p>
                            <br/>
                            <p className="text-lg leading-relaxed mt-4 mb-4">
                                <b>RG</b> is a fully web-based application that comes pre-equipped with a comprehensive Enterprise Risk Management System (ERMS) System. The ERMS consists of following core components:
                            </p>
                            <img
                                alt="..."
                                className="inline-block hide-on-mobile"
                                src={require("assets/img/erm.png").default}
                            />
                            <p className="text-lg leading-relaxed mt-4 mb-4">
                                These modules have the capability to integrate with each other, ensuring a seamless risk management process where information can flow through various risk management functions easily.
                            </p>
                        </div>
                    </div>

                    <div className="container mx-auto px-4">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                                <img
                                    alt="..."
                                    className="max-w-full rounded-lg shadow-xl"
                                    style={{
                                        transform:
                                            "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                                    }}
                                    src={require("assets/img/sample/Dashboard.png").default}
                                />
                            </div>
                            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                                <div className="md:pr-12">
                                    <ul className="list-none mt-6">
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        <b>Solution Flexibility</b> -
                                                        Risiko Guardian is dynamic and highly configurable, capable of adapting to multiple methodologies.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        <b>Dynamic workflow</b> -
                                                        Flexible workflow configuration allows assessments to be reviewed by all levels of business leaders.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        <b>Active Notification Engine</b> -
                                                        Pro-active tracking, notification and alert mechanism ensures tasks are not forgotten and are completed on-time.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        <b>Streamline & Simplicity</b> -
                                                        The system interface is clean and user-friendly, enables business users to navigate across the system seamlessly.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        <b>Data Analytics Driven</b> -
                                                        Readily available dashboard allows multi-dimensional data analysis, enable business leaders to easily prioritise and focus on the risks that matter the most.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-16 relative block risiko-guardian-bg">
                    <div className="container mx-auto px-4 lg:pt-8 lg:pb-64">
                        <div className="flex flex-wrap justify-center">
                            <div className="w-full lg:w-10/12 px-4">
                                <h2 className="text-4xl text-center font-semibold text-white">
                                    GRC Synergy: Unifying Excellence with Risiko Guardian
                                </h2>
                                <p className="text-lg leading-relaxed mt-4 mb-4 text-white">
                                    Discover our comprehensive GRC Solutions, meticulously designed to empower organisations with a holistic approach to Governance, Risk and Compliance. Seamlessly integrating processes and technologies, you can navigate the intricate landscape of governance, assess and manage risk effectively, and maintain compliance with confidence. Drive sustainable growth and achieve your strategic objectives with Risiko Guardian, tailored made to fit your unique organizational needs and aspirations.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container mx-auto">
                        <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg lg:-mt-64 sm:mt-0 py-6 px-12 relative z-10">
                            <div className="w-full text-center lg:w-8/12">
                                <h3 className="font-semibold text-3xl">
                                    Get in touch with us today.
                                </h3>
                                <div className="sm:block flex flex-col mt-6">
                                    <a
                                        href="/contact_us"
                                        target="_blank"
                                        className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                    >
                                        Contact Us
                                    </a>
                                </div>
                                <div className="text-center mt-6"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
