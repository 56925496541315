/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function About_Us() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="header relative pt-16 items-center flex  h-screen max-h-860-px">
                <div className="container mx-auto items-center flex flex-wrap">
                    <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
                        <div className="pt-32 sm:pt-0">
                            <h2 className="font-semibold text-4xl text-blueGray-600">
                                We believed GRC can be made EASY!
                            </h2>
                            <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                <b>Risiko Guardian (RG)</b> automates and simplifies risk and compliance, fostering a proactive risk culture. We help businesses make better decisions, achieve greater transparency, and build a strong foundation for long-term growth.
                            </p>
                            <div className="mt-12">
                                <Link
                                    to="/contact_us"
                                    target="_blank"
                                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <img
                    className="hide-on-mobile absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
                    src={require("assets/img/red_pattern.png").default}
                    alt="..."
                />
            </section>

            <section className="mt-24 md:mt-20 pb-20 relative bg-blueGray-100">
                <div className="container mx-auto px-4 pb-10 pt-8">
                    <div className="items-center flex flex-wrap">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
                                <p className="text-lg leading-relaxed mt-4 mb-4">
                                    <b>Risiko Guardian (RG)</b> is a pioneering Malaysian company blending technical expertise, project implementation, and Risk Management sales. Committed to innovation, we develop cost-effective, advanced risk management solutions through extensive research.
                                </p>
                            </div>
                        </div>

                        <div className="w-full md:w-5/12 ml-auto px-12 md:px-4 pt-8">
                            <div className="md:pr-12">
                                <h3 className="text-3xl font-semibold">
                                    Mission
                                </h3>
                                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                    To develop an innovative GRC solution to automate and simplify GRC processes.
                                </p>
                                <br />
                                <h3 className="text-3xl font-semibold">
                                    Vision
                                </h3>
                                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                    To provide cutting-edge GRC technologies in empowering organisation to stay ahead of evolving risk and compliance landscape.
                                </p>
                            </div>
                        </div>

                        <div className="hide-on-mobile w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                            <img
                                alt="..."
                                className="max-w-full rounded-lg shadow-xl"
                                style={{
                                    transform:
                                        "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                                }}
                                src={require("assets/img/sample/Login Page.png").default}
                            />
                        </div>
                    </div>
                </div>

                <div className="container mx-auto px-4 pb-10 pt-16">
                    <div className="items-center flex flex-wrap">
                        <div className="hide-on-mobile w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                            <img
                                alt="..."
                                className="max-w-full rounded-lg shadow-xl"
                                style={{
                                    transform:
                                        "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                                }}
                                src={require("assets/img/sample/Risk Register Form.png").default}
                            />
                        </div>
                        <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
                            <div className="md:pr-12">
                                <h2 className="font-semibold text-4xl">Core Values</h2>
                                <ul className="list-none mt-6">
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                    <i className="fas fa-star"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    <b>Customer Focus</b> – Customer first and we value feedbacks from customers.
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                    <i className="fas fa-star"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    <b>Innovative</b> – Constantly innovate and growth with our customers.
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                    <i className="fas fa-star"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    <b>Integrity</b> – Transparent and honest in dealing with customers.
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="block relative z-1 risiko-guardian-bg">
                <div className="container mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4">
                            <div className="flex flex-wrap">

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-blueGray-100 relative pt-0 pb-4">
                <div className="container mx-auto">
                    <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-50 py-6 px-12 relative z-10">
                        <div className="w-full text-center lg:w-8/12">
                            <h3 className="font-semibold text-3xl">
                                Get in touch with us today.
                            </h3>
                            <div className="sm:block flex flex-col mt-6">
                                <Link
                                    to="/contact_us"
                                    target="_blank"
                                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    Contact Us
                                </Link>
                            </div>
                            <div className="text-center mt-6"></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
