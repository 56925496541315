import React from "react";
import { Link } from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function News_And_Blog() {
    return (
        <>
            <Navbar transparent />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: `url(${require("assets/img/component-img.png").default})`,
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-10/12 px-4 ml-auto mr-auto">
                                <div className="pr-12">
                                    <h1 className="text-white font-semibold text-5xl text-center">
                                        Insights Hub: Stay Informed with Our Latest News and Engaging Blogs
                                    </h1>
                                    <p className="mt-4 text-lg text-blueGray-200">
                                        Welcome to our Insights Hub, your go-to destination for staying informed and engaged. Explore our latest news to stay updated on industry
                                        trends, company updates, and relevant happenings. Dive into our thoughtfully curated blogs offering expert insights, tips, and valuable
                                        information to empower your business decisions. Whether you're seeking the latest industry news or looking for in-depth analysis,
                                        our Insights Hub is the place to be. Stay ahead of the curve and expand your knowledge�immerse yourself in a world of informative content,
                                        all in one convenient location.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-risiko-guardian-bg fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </div>

                <section className="relative py-20">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-white fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                                <img
                                    alt="..."
                                    className="max-w-full rounded-lg shadow-lg"
                                    src={require("assets/img/component-img.png").default}
                                />
                            </div>
                            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                                <div className="md:pr-12">
                                    <h3 className="text-3xl font-semibold">In the Headlines: Breaking News and Industry Highlights</h3>
                                    <ul className="list-none mt-6">
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-highlighter"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Tech Innovations Revolutionizing the Way We Work and Connect
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-highlighter"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Global Sustainability Initiatives on the Rise: A Look at Progress and Challenges
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-highlighter"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Financial Markets Respond to Economic Shifts: Insights from Leading Analysts
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-20 pb-48">
                    <div className="container mx-auto px-4">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                                <div className="md:pr-12">
                                    <h3 className="text-3xl font-semibold">Insights Unveiled: Explore Our Informative Blogs and Expert Commentary</h3>
                                    <ul className="list-none mt-6">
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-highlighter"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        <b>Blog Title</b>: "Navigating Cybersecurity in a Digital Age: Top Strategies for Safeguarding Your Business"
                                                        <br/>
                                                        <b>Description</b>: Explore the best practices and strategies to fortify your business against cyber threats in today's fast-paced digital landscape.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-highlighter"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        <b>Blog Title</b>: "The Art of Leadership: Unveiling the Traits of Visionary Leaders"
                                                        <br />
                                                        <b>Description</b>: Gain valuable insights into the essential traits that define visionary leaders and learn how to cultivate them for success in leadership roles.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="fas fa-highlighter"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        <b>Blog Title</b>: "Sustainable Business Practices: A Guide to Creating a Greener Tomorrow"
                                                        <br />
                                                        <b>Description</b>: Dive into sustainable business practices and discover how your organization can contribute to a more environmentally friendly and sustainable future.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                                <img
                                    alt="..."
                                    className="max-w-full rounded-lg shadow-lg"
                                    src={require("assets/img/component-img.png").default}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pb-20 relative block risiko-guardian-bg">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-risiko-guardian-bg fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
                        <div className="flex flex-wrap justify-center">
                            <div className="w-full lg:w-10/12 px-4">
                                <h2 className="text-4xl text-center font-semibold text-white">
                                    Navigating the Governance, Risk, and Compliance Landscape: A Comprehensive GRC Solution Guide
                                </h2>
                                <p className="text-lg leading-relaxed mt-4 mb-4 text-white">
                                    Stay informed and empowered with our in-depth coverage of Governance, Risk, and Compliance (GRC) solutions. From compliance frameworks to risk
                                    management strategies, this blog offers expert insights and analysis to help organizations efficiently navigate the complex GRC landscape.
                                    Explore cutting-edge technologies and best practices that streamline processes, enhance transparency, and strengthen your organization's resilience
                                    against evolving regulatory challenges. Whether you're a compliance officer, risk manager, or technology enthusiast, this blog is your go-to resource
                                    for mastering GRC in today's dynamic business environment.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
